<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For the hypothetical reaction,
        <chemical-latex content="A + B -> C," />
        the following data was acquired:
      </p>

      <v-simple-table style="max-width: 500px" class="mb-4">
        <thead>
          <tr>
            <th colspan="2" style="text-align: center">
              <stemble-latex content="$\textbf{Exp 1: [B]}_0\textbf{ = 0.450 M}$" />
            </th>
            <th></th>
            <th colspan="2" style="text-align: center">
              <stemble-latex content="$\textbf{Exp 2: [B]}_0\textbf{ = 0.900 M}$" />
            </th>
          </tr>
          <tr>
            <th>
              <stemble-latex content="$\text{Time (s)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex content="$\text{[A] (mM)}$" />
            </th>
            <th></th>
            <th style="text-align: center">
              <stemble-latex content="$\text{Time (s)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex content="$\text{[A] (mM)}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(timeValue, i) in timeValues1" :key="timeValue">
            <td style="text-align: center">
              {{ timeValues1[i].toFixed(2) }}
            </td>
            <td style="text-align: center">
              {{ concValues1[i].toFixed(4) }}
            </td>
            <td></td>
            <td style="text-align: center">
              {{ timeValues2[i].toFixed(2) }}
            </td>
            <td style="text-align: center">
              {{ concValues2[i].toFixed(4) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) What is
        <stemble-latex content="$\text{k}_\text{obs}$" />
        for the first experiment when
        <stemble-latex content="$\text{[B]}_0=0.450\,\text{M?}$" />
        Note that you will have to graph the data to determine this value.
      </p>

      <calculation-input
        v-model="inputs.kobs1"
        prepend-text="$\text{k}_\text{obs}:$"
        append-text="$\text{Units not provided}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What is
        <stemble-latex content="$\text{k}_\text{obs}$" />
        for the second experiment when
        <stemble-latex content="$\text{[B]}_0=0.900\,\text{M?}$" />
        As you did in part a), you will have to graph the data to determine this value.
      </p>

      <calculation-input
        v-model="inputs.kobs2"
        prepend-text="$\text{k}_\text{obs}:$"
        append-text="$\text{Units not provided}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        c) Use your answers from parts a) and b) to determine the order of the reaction with respect
        to B.
      </p>

      <v-radio-group v-model="inputs.orderB" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsOrder" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-n3">
        d) What is the rate equation (rate law) predicted by this step of the reaction?
      </p>

      <v-radio-group v-model="inputs.rateLaw" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in optionsRateLaw" :key="option.value">
          <v-radio class="d-inline-block mb-3" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        e) Calculate the rate constant,
        <stemble-latex content="$\text{k,}$" />
        for the reaction.
      </p>

      <calculation-input
        v-model="inputs.rateConstant"
        prepend-text="$\text{k}:$"
        append-text="$\text{Units not provided}$"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </div>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question475',
  components: {
    NumberValue,
    CalculationInput,
    STextarea,
    AiLoadingOverlay,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        kobs1: null,
        kobs2: null,
        orderB: null,
        rateLaw: null,
        rateConstant: null,
      },
      optionsOrder: [
        {expression: '$\\text{Zeroth}$', value: 'zeroth'},
        {expression: '$\\text{First}$', value: 'first'},
        {expression: '$\\text{Second}$', value: 'second'},
        {expression: '$\\text{Third}$', value: 'third'},
        {expression: '$\\text{More information needed}$', value: 'moreInfoNeeded'},
      ],
      optionsRateLaw: [
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[A]}}$',
          value: 'kA',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[B]}}$',
          value: 'kB',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[A][B]}}$',
          value: 'kAB',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[A]^2}}$',
          value: 'kA2',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[B]^2}}$',
          value: 'kB2',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[A]^2[B]}}$',
          value: 'kA2B',
        },
        {
          expression: '$\\text{rate}=\\displaystyle{k\\ce{[A][B]^2}}$',
          value: 'kAB2',
        },
      ],
    };
  },

  computed: {
    Aconc0() {
      return this.taskState.getValueBySymbol('Aconc0').content;
    },
    rateConstantUS() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    rateConstant() {
      return this.rateConstantUS.toFloat() * 10;
    },
    timeValues1() {
      return [0, 5, 10, 15, 20, 25];
    },
    concValues1() {
      return this.timeValues1.map(
        (time) => this.Aconc0.toFloat() * Math.exp(-this.rateConstant * Math.pow(0.45, 2) * time)
      );
    },
    timeValues2() {
      return [0, 1, 2, 4, 8, 12];
    },
    concValues2() {
      return this.timeValues2.map(
        (time) => this.Aconc0.toFloat() * Math.exp(-this.rateConstant * Math.pow(0.9, 2) * time)
      );
    },
  },
};
</script>
